<template>
  <div class="grid grid-cols-1 xl:grid-cols-4">
    <div class="h-full flex flex-col p-4 xl:col-span-3">
      <modalExport ref="modalExport" show-modal-sync="showmodal" />

      <!--Graph Card-->
      <div class="w-full mt-0 grid grid-cols-1 grid-rows-2 gap-1">
        <div class="pl-2">
          <span
            style="font-family: 'Roboto', sans-serif"
            class="text-raphal-valider font-thin mr-5 text-6xl pt-1 m-auto align-bottom"
          >
            {{ pageTitle }}
          </span>
        </div>
        <div class="flex">
          <div class="relative w-1/6 p-2 cursor-pointer" @click="filterProjects('total')">
            <div
              class="flex w-full justify-center items-center h-12 m-auto rounded border-2 border-raphal-total hover:bg-gray-500 hover:border-gray-400 is-active:bg-gray-500 shadow-md"
              :class="[ ((activeFilter === 'total') && (currentRoute !== 'bookmark')) ? 'text-white bg-gray-500 hover:text-gray-300' : 'hover:text-white bg-white', (!bookmark && (activeFilter === 'total')) ? 'is-active' : '' ]"
            >
              <span v-if="projectList" class="text-2xl">{{ filterProjectStats.total }}</span>
            </div>
            <span
              ref="navfilter-total"
              class="absolute border px-2 border-raphal-total bg-white right-0 -top-1 rounded text-gray-500 text-xs ml-2 mt-1 wrapText hover:text-white hover:bg-raphal-total"
            >Total</span>
          </div>
          <div class="relative w-1/6 p-2 cursor-pointer" @click="gotoBookmark()">
            <div
              class="flex w-full justify-center items-center h-12 m-auto bg-white rounded border-2 border-raphal-total hover:text-white hover:bg-gray-400 hover:border-gray-500 is-active:bg-gray-400 shadow-md"
              :class="[ bookmark ? 'is-active' : '' ]"
            >
              <span v-if="projectList" class="text-2xl">{{ filterProjectStats.bookmarked }}</span>
            </div>
            <span
              ref="navfilter-bookmark"
              class="absolute border px-2 border-raphal-total bg-white right-0 -top-1 rounded text-gray-500 text-xs ml-2 mt-1 wrapText"
            >Suivis</span>
          </div>
          <div class="relative w-1/6 p-2 cursor-pointer" @click="filterProjects('validate')">
            <div
              class="flex w-full justify-center items-center h-12 m-auto bg-white rounded border-2 border-raphal-valider hover:text-white hover:bg-gray-400 hover:border-gray-500 is-active:bg-gray-400 shadow-md"
              :class="[ (!bookmark && (activeFilter === 'validate')) ? 'is-active' : '' ]"
            >
              <span v-if="ListProjects" class="text-2xl">{{ filterProjectStats.validate }}</span>
            </div>
            <span
              ref="navfilter-validate"
              class="absolute border px-2 border-raphal-valider bg-white right-0 -top-1 rounded text-gray-500 text-xs ml-2 mt-1 wrapText"
            >A Valider</span>
          </div>
          <div class="relative w-1/6 p-2 cursor-pointer" @click="filterProjects('traite')">
            <div
              class="flex w-full justify-center items-center h-12 m-auto bg-white rounded border-2 border-raphal-traiter hover:text-gray-500 hover:bg-raphal-traiter hover:border-tale-500 is-active:bg-raphal-traiter shadow-md"
              :class="[ (!bookmark && (activeFilter === 'traite')) ? 'is-active' : '' ]"
            >
              <span v-if="ListProjects" class="text-2xl">{{ filterProjectStats.traite }}</span>
            </div>
            <span
              ref="navfilter-traite"
              class="absolute border px-2 border-raphal-traiter bg-white right-0 -top-1 rounded text-gray-500 text-xs ml-2 mt-1 wrapText"
            >A Traiter</span>
          </div>
          <div class="relative w-1/6 p-2 cursor-pointer" @click="filterProjects('termine')">
            <div
              class="flex w-full justify-center items-center h-12 m-auto bg-white rounded border-2 border-raphal-termine hover:text-white hover:bg-raphal-termine is-active:bg-raphal-termine shadow-md"
              :class="[ (!bookmark && (activeFilter === 'termine')) ? 'is-active' : '' ]"
            >
              <span v-if="ListProjects" class="text-2xl">{{ filterProjectStats.termine }}</span>
            </div>
            <span
              ref="navfilter-termine"
              class="absolute border px-2 border-raphal-termine bg-white right-0 -top-1 rounded text-gray-500 text-xs ml-2 mt-1 wrapText"
            >En cours</span>
          </div>
          <div class="relative w-1/6 p-2 cursor-pointer" @click="filterProjects('archived')">
            <div
              class="flex w-full justify-center items-center h-12 m-auto bg-white rounded border-2 border-raphal-archiver hover:text-gray-500 hover:bg-gray-300 hover:border-gray-300 is-active:bg-gray-400 shadow-md"
              :class="[ (!bookmark && (activeFilter === 'archived')) ? 'is-active' : '' ]"
            >
              <span v-if="ListProjects" class="text-2xl">{{ filterProjectStats.archived }}</span>
            </div>
            <span
              ref="navfilter-archived"
              class="absolute border px-2 border-raphal-archiver bg-white right-0 -top-1 rounded text-gray-400 text-xs ml-2 mt-1 wrapText"
            >Terminé</span>
          </div>
          <div class="relative w-1/6 p-2 cursor-pointer" @click="filterProjects('controle')">
            <div
              class="flex w-full justify-center items-center h-12 m-auto bg-white rounded border-2 border-raphal-controler hover:text-white hover:bg-raphal-controler hover:border-red-800 is-active:bg-raphal-controler shadow-md"
              :class="[ (!bookmark && (activeFilter === 'controle')) ? 'is-active' : '' ]"
            >
              <span v-if="ListProjects" class="text-2xl">{{ filterProjectStats.controle }}</span>
            </div>
            <span
              ref="navfilter-controle"
              class="absolute border px-2 border-raphal-controler bg-white right-0 -top-1 rounded text-gray-500 text-xs ml-2 mt-1 wrapText"
            >Géoréf</span>
          </div>
          <div class="relative w-1/6 p-2 cursor-pointer" @click="filterProjects('echec')">
            <div
              class="flex w-full justify-center items-center h-12 m-auto bg-white rounded border-2 border-raphal-echec hover:text-white hover:bg-red-400 hover:border-red-700 is-active:bg-red-400 shadow-md"
              :class="[ (!bookmark && (activeFilter === 'echec')) ? 'is-active' : '' ]"
            >
              <span v-if="projectList" class="text-2xl">{{ filterProjectStats.echec }}</span>
            </div>
            <span
              ref="navfilter-echec"
              class="absolute border px-2 border-raphal-echec bg-white right-0 -top-1 rounded text-gray-500 text-xs ml-2 mt-1 .wrapText"
            >Echec</span>
          </div>
          <div v-if="role == 'admin'" class="relative w-1/6 p-2 cursor-pointer" @click="filterProjects('deleted')">
            <div
              class="flex w-full justify-center items-center h-12 m-auto bg-white rounded border-2 border-raphal-echec hover:text-white hover:bg-red-400 hover:border-red-600 is-active:bg-red-400 shadow-md"
              :class="[ (!bookmark && (activeFilter === 'deleted')) ? 'is-active' : '' ]"
            >
              <span v-if="projectList" class="text-2xl">{{ filterProjectStats.deleted }}</span>
            </div>
            <span
              ref="navfilter-deleted"
              class="absolute border px-2 border-raphal-echec bg-white right-0 -top-1 rounded text-gray-500 text-xs ml-2 mt-1 .wrapText"
            >Supprimés</span>
          </div>
        </div>
      </div>

      <div class="flex w-full relative mt-2">
        <span
          class="absolute border px-2 border-gray-500 bg-white -right-1 -top-2 rounded text-gray-500 text-xs ml-2 mb-1"
        >Filtres</span>
        <div class="w-full flex border-2 rounded border-gray-500 bg-white p-2 mb-2 flex-col">
          <div class="w-full flex bg-white p-2 pt-0">
            <div class="w-1/3">
              <label class="block text-gray-700 text-sm font-bold mb-1" for="nomProjet"> Nom projet </label>
              <input
                id="nomProjet"
                v-model="nomProjet"
                class="shadow appearance-none border text-xl border-gray-1 rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
              >
            </div>
            <div class="w-1/3 ml-3">
              <label class="block text-gray-700 text-sm font-bold mb-1" for="commentaire"> Commentaire </label>
              <input
                id="commentaire"
                v-model="commentaire"
                class="shadow appearance-none border text-xl border-gray-1 rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
              >
            </div>
            <div class="w-1/3 ml-3 float-right">
              <label class="block text-gray-700 text-sm font-bold mb-1" for="periodeFilter"> Dates </label>
              <date-picker
                id="periodeFilter"
                v-model="period"
                class="min-w-full rounded w-full"
                type="date"
                range
                placeholder="Sélectionner deux dates"
              />
            </div>
            <div class="p-0 pt-5 ml-4">
              <button
                class="w-10 h-10 mx-1 shadow-raphal rounded-md border border-raphal-valider flex items-center bg-white text-raphal-valider cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:text-raphal-valider focus:outline-none"
                @click="filterProjects('search')"
              >
                <span class="text-center w-full">
                  <svg
                    aria-hidden="true"
                    width="20"
                    height="35pt"
                    class="w-full text-raphal-valider"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <div class="tab2 flex flex-row p-0 pt-5">
              <input
                id="filtres"
                v-model="showMore"
                class="float-right w-0 hidden right-0 opacity-0"
                type="checkbox"
                name="tabs"
              >
              <label
                class="w-24 pr-2 my-auto float-right relative right-0 leading-normal text-center cursor-pointer"
                for="filtres"
              />
            </div>
          </div>
          <div :class="{ 'h-0 overflow-hidden hover:h-full': true, 'h-full': showMore, 'max-h-full': showMore }">
            <div class="w-full flex bg-white p-0">
              <FilterProjects
                :export-projects="exportProjects"
                :init-list-projects="initListProjects"
                :search-projects="searchProjects"
                class="w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white flex flex-col flex-grow relative">
        <div
          v-if="fetching"
          class="h-full w-full flex absolute flex-grow rounded-md z-10 p-4 justify-center opacity-50 text-xl text-center font-bold text-white items-center bg-raphal-valider"
        >
          <svg
            class="w-1/5 animate-spin h-9 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
        <div
          class="overflow-visible w-full h-auto lg:rounded lg:flex lg:mb-1 lg:border-raphal-total lg:border relative"
        >
          <!-- <div v-if="projectData.rtk==true" class="ribbon ribbon-top-right"><span>RTK</span></div> -->
          <div class="hidden w-full lg:grid lg:grid-cols-cardContainerProjectsLg">
            <div
              class="w-full p-0 px-2 grid grid-cols-cardProjects gap-2 grid-flow-row-dense justify-start items-center sm:grid-cols-cardProjectsSm lg:grid-cols-cardProjectsLg"
            >
              <div style="width: 100px" class="stories bg-white" />

              <div
                class="text-black font-bold text-xs mb-0"
                style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
              >
                <span class="text-grey-darker text-base"> Projet</span>
              </div>
              <div class="text-black font-bold text-base mb-0">
                <span class="text-base">Etat </span>
              </div>
              <div class="text-black font-bold text-base mb-0">
                <span class="text-base"> Date</span>
              </div>
              <div class="text-black font-bold text-base mb-0">
                <span class="text-base">Opérateur</span>
              </div>
              <div class="text-black font-bold text-base mb-0">
                <span class="text-base">Commentaire</span>
              </div>
              <div class="text-black font-bold text-base mb-0">
                <span class="text-base">INSEE</span>
              </div>
              <div class="text-black font-bold text-base mb-0 justify-center items-center flex">
                <span class="text-base">Projet</span>
              </div>
            </div>
            <div class="pl-2 justify-center items-center p-1 grid grid-cols-1 grid-rows-1">
              <div class="text-black font-bold text-center text-base mb-0">
                <span class="text-base"> Actions</span>
              </div>
            </div>
          </div>
        </div>
        <div v-for="item in listPaginated" :key="item.id">
          <ProjectCard
            :nom-projet="item.nom"
            :project-data="item"
            :validate-project="validateProject"
            :date-depot="new Date(item.dateDepot)"
            :premium="item.premiumProject"
            :etat-projet="item.etatGeoref"
            :georeferenced="item.georeferenced"
            :idprojet="item.identifiant + ''"
          />
        </div>
      </div>
      <div :disabled="fetching" class="w-full relative block bottom-0 bg-white">
        <t-pagination
          ref="projectsPagination"
          :disabled="fetching"
          :v-if="totalPages > pagecount"
          :value="1"
          :hide-prev-next-controls="false"
          :total-items="totalPages"
          :per-page="pagecount"
          :class="{
            'ml-auto': !false,
            'mx-auto': false
          }"
          @change="getPage"
        />
      </div>
      <!--/Graph Card-->
    </div>
    <div class="h-full w-full p-4 pb">
      <MapContainer ref="mapcontainer" :projects-data="[]" />
      <!-- <UploadFile /> -->
    </div>
  </div>
</template>

<script>
import MapContainer from '@/components/MapContainer.vue';
import ProjectCard from '@/components/ProjectCard.vue';
import modalExport from './ModalExport.vue';
import { mapGetters } from 'vuex';
import { apolloProjectClient } from '../vue-apollo';
// import ProjectFusionCard from "@/components/ProjectFusionCard.vue";
// import UploadFile from "@/components/UploadFile.vue";
import FilterProjects from '@/components/FilterProjectsPagination.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/fr';
import gql from 'graphql-tag';
import { routeNames } from '../router';
import EventBus from '../event-bus';

export default {
  name: 'MainComponent',
  components: {
    MapContainer,
    ProjectCard,
    // ProjectFusionCard,
    modalExport,
    DatePicker,
    FilterProjects
  },
  props: {
    fusionList: { type: Array, default: null },
    bookmark: { type: Boolean, default: false }
  },
  data() {
    return {
      showMenu: true,
      ListProjects: [],
      listPaginated: [],
      componentKey: 0,
      nomProjet: '',
      commentaire: '',
      period: null,
      numPage: 1,
      showMore: false,
      pagecount: 12,
      filterCriteria: 'total',
      advancedFilter: {},
      advancedQuery: [],
      fetching: false,
      sameRoute: false,
      samePage: true
    };
  },
  computed: {
    ...mapGetters({
      role: 'Auth/roleUser'
    }),
    currentRoute() {
      return this.$route.name;
    },
    activeFilter() {
      return this.sameRoute ? this.filterCriteria : this.$raphalUtils.projectsCriteria;
    },
    get3DStatus() {
      return this.$raphalUtils.get3DStatus(this.etatTraitement);
    },
    pageTitle: function() {
      return this.$raphalUtils.getPageTitle(this.bookmark);
    },
    projectList: function () {
      return this.$raphalUtils.parseProjectsList(this.allProjetsPaginated);
    },
    filterProjectStats: function () {
      return this.$raphalUtils.parseProjectStats(this.filterStatsProjects);
    },
    totalPages: function () {
      if (this.filterCriteria == 'search') {
        return this.allProjetsPaginated.totalCount;
      } 
      if (this.bookmark) {
        return this.filterProjectStats['bookmarked'];
      }
      return this.filterProjectStats[this.filterCriteria];
    }
  },  
  watch: {
    projectList: function () {
      this.ListProjects = this.projectList;
      // .filter(function(proj) {
      //     return parseInt( proj.etatGeoref) < 4 ;
      // });
      this.ListTotal = this.ListProjects;
      this.$refs.mapcontainer.setprojectsData(this.ListProjects);
      this.getPage(this.numPage);
    },
    showMore: function () {
      this.pagecount = this.showMore == true ? 7 : 11;
      this.fotceUpdate();
      this.getPage(this.numPage);
    }
  },
  mounted() {
    this.pagecount = 10;
    if (this.$route.name !== routeNames.bookmark) {
      this.$raphalUtils.lastActiveRouteName = this.$route.name;
    }
  },
  updated() {
    this.deactivateFilterEvent();
    this.activateFilterEvent();
  },
  methods: {
    fotceUpdate: function () {
      this.componentKey += 1;
    },
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
    activateFilterEvent: function() {
      EventBus.$on("callProjectsFilter", ({ filter }) => {
        if (filter && (filter !== this.$raphalUtils.projectsCriteria)) {
          this.$raphalUtils.projectsCriteria = filter;
          this.sameRoute = false;
          this.runFilter(filter);
        }
      });
    },
    deactivateFilterEvent: function() {
      EventBus.$off('callProjectsFilter');
      if (!this.sameRoute && !this.samePage) {
        this.$raphalUtils.projectsCriteria = 'total';
      }
    },
    refetchProjects: function() {
      this.$apollo.queries.allProjetsPaginated.refetch();
      this.$apollo.queries.filterStatsProjects.refetch();
    },
    runFilter: function(criteria) {
      // this.ListProjects =list;
      // this.$refs.mapcontainer.setprojectsData(list);
      // this.paginatedProjects()
      this.filterCriteria = criteria;
      let filters = {};
      filters['nom'] = this.nomProjet;
      filters['commentaire'] = this.commentaire;

      if (this.period != null && this.period[0] != null) {
        let enddd = new Date(this.period[1]);
        enddd.setTime(enddd.getTime() + 24 * 60 * 60 * 1000 - 1);
        filters['dateDepot'] = JSON.stringify([this.period[0], enddd.toISOString()]);
      }

      for (let rule of this.advancedQuery) {
        if (rule.value !== '' && rule.value !== null) {
          filters[rule.identifier] = rule.value;
        }
      }

      this.advancedFilter = filters;
      this.fetching = true;
      this.sameRoute = true;
      this.$apollo.queries.allProjetsPaginated.refetch();
      if (this.$refs.projectsPagination) {
        this.$refs.projectsPagination.goToFirstPage();
      }
    },
    filterProjects: function (criteria) {
      const lastActiveRouteName = this.$raphalUtils.lastActiveRouteName || routeNames.home;
      this.$raphalUtils.projectsCriteria = criteria;
      if (this.$route.name !== lastActiveRouteName) {
        this.$raphalUtils.goto({
          name: lastActiveRouteName,
          params: {
            filter: criteria
          }
        });
      } else {
        this.runFilter(criteria);
      }
    },
    initListProjects: function () {
      this.ListProjects = this.projectList;

      if (this.nomProjet != '') this.ListProjects = this.filterbyName(this.ListProjects);
      if (this.period != null && this.period[0] != null) {
        this.ListProjects = this.filterbyDate(this.ListProjects);
      }
      this.$refs.mapcontainer.setprojectsData(this.ListProjects);
      this.paginatedProjects();
    },
    gotoBookmark() {
      this.$raphalUtils.lastActiveRouteName = this.$route.name;
      this.$raphalUtils.goto('/bookmark');
    },
    getCoordinate(item, index = 0) {
      if (item && item.geom && Array.isArray(item.geom.coordinates) && item.geom.coordinates.length > index) {
        return item.geom.coordinates[index];
      }
      return '';
    },
    async exportProjects() {
      try {
        let { data: data } = await apolloProjectClient.query({
          query: gql`
            query exportProjectsCSV($criteria: String!, $advancedFilters: String!) {
              exportProjectsCSV(criteria: $criteria, advancedFilters: $advancedFilters) {
                id
                geom
                nom
                epsg
                etatTraitement
                etatPreTraitement
                etatReceptionPhotos
                nbTotalImages
                etatGeoref
                georeferenced
                precisionGeoref
                needGeorefUpdate
                modelApn
                focalLength
                dateDepot
                resolutionX
                resolutionY
                ErreurMoyEchelle
                ErreurSMaxEchelle
                tempsDeep
                dateFinDeep
                dateGeoref
                operateurTerrain
                operateurGeoref
                operateurDigit
                typeTraveau
                sectionSize
                commentaire
                grilleRepiquage
                longueur
                statusProjet
                premiumProject
                dateDigitalisation
                digitalised
                commande
                rtk
                projectPath
                manager
                commune
                insee
                operateurValidation
                dateValidation
                operateurArchivage
                dateArchivage
                archived
                versionApp
                indiceIonoSphere
                scores
                scoresMobile
              }
            }
          `,
          fetchPolicy: 'network-only',
          variables: {
            criteria: this.filterCriteria,
            advancedFilters: JSON.stringify(this.advancedFilter)
          }
        });

        let projectExport = data.exportProjectsCSV;
        let etatTraitement = ['En attente', 'En cours', 'Complet', 'Incomplet', 'Echec'];
        let header = [
          'ID',
          'NOM PROJET',
          'LAT',
          'LON',
          'TYPE DE TRAVAUX',
          'SECTION EQUIPEMENT',
          'COMMENTAIRE',
          'MARQUAGE/PIQUETAGE',
          'LONGUEUR PROJET',
          'MODELE SMARTPHONE',
          'NBR IMAGES PROJET',
          'COMMANDE',
          'INSEE',
          'COMMUNE',
          'RESULTATS 3D',
          'GEOREFERENCEMENT ',
          'DIGITALISE',
          'ERREUR MOY MAE (m)',
          'ERREUR MAX MAE (m)',
          'DATE DEPÔT',
          'DATE FIN CALCUL 3D',
          'DATE GEOREFERENCEMENT',
          'DATE DIGITALISATION',
          'OPERATEUR TERRAIN',
          'OPERATEUR GEOREFERENCEMENT',
          'OPERATEUR DIGITALISATION',
          'OPERATEUR VALIDATION',
          'DATE VALIDATION',
          'OPERATEUR ARCHIVAGE',
          'DATE ARCHIVAGE',
          'VERSION APP MOBILE',
          'INDICE IONOSPHERIQUE',
          'SCORE GLOBALE',
          'SCORE PHOTOGRAMMETRIE',
          'SCORE GEOREFERENCEMENT',
          'SCORE CAPTATION',
          'PRECISITION',
          'VITESSE CAPTATION',
          'POURCENTAGE IMAGES EN RTK',
          'SCORE MATCHING'
        ];
        if (this.role == 'admin') {
          header.push('MANAGER');
          header.push('VALIDE');
          header.push('UUID');
        }
        const csvString = [
          header,
          ...projectExport.map((item) =>
            this.role == 'admin'
              ? [
                  item.id,
                  item.nom ? item.nom.replaceAll(';', ' ') : '',
                  this.getCoordinate(item, 0),
                  this.getCoordinate(item, 1),
                  item.typeTraveau ? item.typeTraveau.replaceAll(';', ' ') : '',
                  item.sectionSize ? item.sectionSize.replaceAll(';', ' ') : '',
                  item.commentaire ? item.commentaire.replaceAll(';', ' ') : '',
                  item.grilleRepiquage ? 'Oui' : 'Non',
                  item.longueur,
                  item.modelApn,
                  item.nbTotalImages,
                  item.commande ? item.commande.replaceAll(';', ' ') : '',
                  item.insee,
                  item.commune,
                  etatTraitement[item.etatTraitement],
                  item.georeferenced ? 'Oui' : 'Non',
                  item.digitalised ? 'Oui' : 'Non',
                  item.ErreurMoyEchelle ? item.ErreurMoyEchelle.toFixed(3) : '',
                  item.ErreurSMaxEchelle ? item.ErreurSMaxEchelle.toFixed(3) : '',
                  new Date(item.dateDepot).toLocaleString(),
                  new Date(item.dateFinDeep).toLocaleString(),
                  item.georeferenced ? new Date(item.dateGeoref).toLocaleString() : '',
                  item.digitalised ? new Date(item.dateDigitalisation).toLocaleString() : '',
                  item.operateurTerrain,
                  item.operateurGeoref,
                  item.operateurDigit,
                  item.operateurValidation,
                  item.premiumProject == 1 ? item.dateValidation : '',
                  item.operateurArchivage,
                  item.archived ? item.dateArchivage : '',
                  item.versionApp,
                  this.getIonoIndexText(item.indiceIonoSphere),
                  this.parseScores(item.scores, 'global'),
                  this.parseScores(item.scores, 'photogrammetry'),
                  this.parseScores(item.scores, 'georeferencement'),
                  this.parseScoresMobile(item.scoresMobile, 'globalScore'),
                  this.parseScoresMobile(item.scoresMobile, 'accuracyScore'),
                  this.parseScoresMobile(item.scoresMobile, 'speedScore'),
                  this.parseScoresMobile(item.scoresMobile, 'rtkScore'),
                  this.parseScoresMobile(item.scoresMobile, 'matchingScore'),
                  item.manager,
                  item.premiumProject == 1 ? 'Oui' : 'Non',
                  item.projectPath
                ]
              : [
                  item.id,
                  item.nom ? item.nom.replaceAll(';', ' ') : '',
                  this.getCoordinate(item, 0),
                  this.getCoordinate(item, 1),
                  item.typeTraveau ? item.typeTraveau.replaceAll(';', ' ') : '',
                  item.sectionSize ? item.sectionSize.replaceAll(';', ' ') : '',
                  item.commentaire ? item.commentaire.replaceAll(';', ' ') : '',
                  item.grilleRepiquage ? 'Oui' : 'Non',
                  item.longueur,
                  item.modelApn,
                  item.nbTotalImages,
                  item.commande ? item.commande.replaceAll(';', ' ') : '',
                  item.insee,
                  item.commune,
                  etatTraitement[item.etatTraitement],
                  item.georeferenced ? 'Oui' : 'Non',
                  item.digitalised ? 'Oui' : 'Non',
                  item.ErreurMoyEchelle ? item.ErreurMoyEchelle.toFixed(3) : '',
                  item.ErreurSMaxEchelle ? item.ErreurSMaxEchelle.toFixed(3) : '',
                  new Date(item.dateDepot).toLocaleString(),
                  new Date(item.dateFinDeep).toLocaleString(),
                  item.georeferenced ? new Date(item.dateGeoref).toLocaleString() : '',
                  item.digitalised ? new Date(item.dateDigitalisation).toLocaleString() : '',
                  item.operateurTerrain,
                  item.operateurGeoref,
                  item.operateurDigit,
                  item.operateurValidation,
                  item.premiumProject == 1 ? item.dateValidation : '',
                  item.operateurArchivage,
                  item.archived ? item.dateArchivage : '',
                  item.versionApp,
                  this.getIonoIndexText(item.indiceIonoSphere),
                  this.parseScores(item.scores, 'global'),
                  this.parseScores(item.scores, 'photogrammetry'),
                  this.parseScores(item.scores, 'georeferencement'),
                  this.parseScoresMobile(item.scoresMobile, 'globalScore'),
                  this.parseScoresMobile(item.scoresMobile, 'accuracyScore'),
                  this.parseScoresMobile(item.scoresMobile, 'speedScore'),
                  this.parseScoresMobile(item.scoresMobile, 'rtkScore'),
                  this.parseScoresMobile(item.scoresMobile, 'matchingScore')
                ]
          )
        ]
          .map((e) => e.join(';'))
          .join('\n');

        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent('\ufeff' + csvString);
        anchor.target = '_blank';
        anchor.download = 'ExportProjets.csv';
        anchor.click();
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },
    getIonoIndexText: function (iono) {
      return this.$raphalUtils.getIonoText(iono);
    },
    parseScores: function (score, key) {
      return this.$raphalUtils.getScoreByKey(key, score);
    },
    parseScoresMobile: function (score, key) {
      return this.$raphalUtils.getMobileScoreByKey(key, score);
    },
    filterbyName: function (list) {
      let that_ = this;
      if (this.nomProjet != '') {
        list = list.filter(function (proj) {
          return `${proj['nom']}`.toLowerCase().includes(`${that_.nomProjet}`.toLowerCase());
        });
      }
      this.filterProjects(list);
      return list;
    },
    filterbyCommande: function (list) {
      let that_ = this;
      if (this.commande != '') {
        list = list.filter(function (proj) {
          return `${proj['commande']}`.toLowerCase().includes(`${that_.commande}`.toLowerCase());
        });
      }
      this.filterProjects(list);
      return list;
    },
    filterbyDate: function (list) {
      if (this.period != null && this.period[0] != null) {
        let dd = new Date(this.period[0]);
        let enddd = new Date(this.period[1]);
        enddd.setTime(enddd.getTime() + 24 * 60 * 60 * 1000 - 1);

        list = list.filter(function (proj) {
          let dateDepot = new Date(proj.dateDepot);
          return dateDepot >= dd && dateDepot <= enddd;
        });
      }
      this.filterProjects(list);
      return list;
    },
    searchProjects: function (query) {
      this.advancedQuery = query.children;
    },
    getPage(num) {
      this.samePage = true;
      this.numPage = Number(num);
      // this.showMoreResults()
      this.paginatedProjects();
    },
    paginatedProjects() {
      this.listPaginated = this.ListProjects;
      // this.$forceUpdate()
    },
    validateProject(item) {
      // this.Listvalidate = this.Listvalidate.filter(function(proj) {
      //     return item.id !== proj.id
      // })
      // if(!this.ListTraite.includes(item)) this.ListTraite.push(item)
      // this.filterProjects(this.Listvalidate)
      // this.$forceUpdate()
      console.log(item);
    },
    showMoreResults: function () {
      // Fetch more data and transform the original result
      this.$apollo.queries.allProjetsPaginated.fetchMore({
        // New variables
        variables() {
          // Use vue reactive properties here
          return {
            offset: this.pagecount * (this.numPage - 1),
            first: this.pagecount,
            nom: this.filterCriteria
          };
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEges = fetchMoreResult.allProjetsPaginated.edges;

          return {
            allProjetsPaginated: {
              __typename: previousResult.allProjetsPaginated.__typename,
              // Merging the tag list
              edges: [...previousResult.allProjetsPaginated.edges, ...newEges]
            }
          };
        }
      });
      return;
    }
  },
  apollo: {
    $client: 'apolloProjectClient',
    allProjetsPaginated: {
      query: gql`
        query getProject($first: Int!, $offset: Int!, $nom: String!, $commentaire: String, $bookmarked: Boolean) {
          allProjetsPaginated(first: $first, offset: $offset, nom: $nom, commentaire: $commentaire, bookmarked: $bookmarked) {
            edges {
              node {
                id
                identifiant
                geom
                nom
                epsg
                etatTraitement
                etatPreTraitement
                etatReceptionPhotos
                nbTotalImages
                etatGeoref
                georeferenced
                bookmarked
                precisionGeoref
                needGeorefUpdate
                modelApn
                focalLength
                dateDepot
                resolutionX
                resolutionY
                ErreurMoyEchelle
                ErreurSMaxEchelle
                tempsDeep
                dateFinDeep
                dateGeoref
                operateurTerrain
                operateurGeoref
                operateurDigit
                typeTraveau
                sectionSize
                commentaire
                qrCode
                grilleRepiquage
                longueur
                statusProjet
                premiumProject
                dateDigitalisation
                digitalised
                commande
                rtk
                projectPath
                manager
                commune
                insee
                scores
                indiceIonoSphere
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
          }
        }
      `,

      variables() {
        const filtername = (this.$route.name === 'bookmark') ? 'total' : this.$raphalUtils.projectsCriteria || this.filterCriteria;
        return {
          offset: this.pagecount * (this.numPage - 1),
          first: Number(this.pagecount),
          nom: filtername,
          commentaire: JSON.stringify(this.advancedFilter),
          bookmarked: this.bookmark
        };
      },
      result() {
        this.fetching = false;
      },
      debounce: 100,
      fetchPolicy: 'no-cache'
    },

    filterStatsProjects: {
      query: gql`
        query {
          filterStatsProjects
        }
      `,
      fetchPolicy: 'no-cache'
    },

    $subscribe: {
      onUpdateProject: {
        query: gql`
          subscription {
            onUpdateProject {
              projet {
                id
                geom
                nom
                statusProjet
                etatTraitement
                premiumProject
              }
            }
          }
        `,
        result(/*{ data }*/) {
          this.fetching = true;
          this.refetchProjects();
        }
      }
    }
  }
};
</script>
<style scoped>
.wrapText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tab2 input:checked + label {
  /*@apply text-sm p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/

  border-left-width: 2px; /*.border-l-2*/
  border-color: #ffffff; /*.border-indigo*/
  background-color: #ffffff; /*.bg-gray-100 */
}
/* Icon */
.tab2 label::after {
  right: 0;
  top: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
/* Icon formatting - closed */
.tab2 input[type='checkbox'] + label::after {
  content: '\002b';
  font-weight: bold; /*.font-bold*/
  border-width: 1px; /*.border*/
  border-radius: 9999px; /*.rounded-full */
  border-color: #b8c2cc; /*.border-grey*/
  /* display: inline-block; */
  text-align: center;
  margin-left: 5px;
  float: right;
}
.tab2 input[type='radio'] + label::after {
  content: '\002b';
  font-weight: bold; /*.font-bold*/
  border-width: 1px; /*.border*/
  border-radius: 9999px; /*.rounded-full */
  border-color: #b8c2cc; /*.border-grey*/
}
/* Icon formatting - open */
.tab2 input[type='checkbox']:checked + label::after {
  transform: rotate(45deg);
  background-color: #4882ac; /*.bg-indigo*/
  color: #f8fafc; /*.text-grey-lightest*/
  text-align: center;
}
.tab2 input[type='radio']:checked + label::after {
  transform: rotate(45deg);
  background-color: #6574cd; /*.bg-indigo*/
  color: #242b31; /*.text-grey-lightest*/
}
.mx-datepicker-range {
  width: 30%;
}
</style>
