<template>
  <div style="height: 100%">
    <div id="loading-screen" class="loading" />
    <canvas id="canvas" class="three-canvas" />
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

export default {
  name: 'ThreeAnimation',
  // props: ['filename'],
  props: {
    'filename': { type: String, default: '' }
  },
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      controls: null,
      canvas: null
    };
  },
  mounted() {
    this.init();
    this.animate();
  },
  methods: {
    init: function () {
      this.canvas = document.getElementById('canvas');

      // renderer
      this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);

      // scene
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0xeeeeee);
      // this.scene.fog = new THREE.FogExp2( 0xcccccc, 0.04 );

      // camera
      this.camera = new THREE.PerspectiveCamera(30, this.canvas.clientWidth / this.canvas.clientHeight, 1, 100);
      this.camera.position.set(5, 5, 5);
      this.camera.up = new THREE.Vector3(0, 0, 1);
      this.scene.add(this.camera);

      // controls
      this.controls = new OrbitControls(this.camera, this.canvas);
      this.controls.autoRotate = true;
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.05;
      this.controls.minDistance = 1;
      this.controls.maxDistance = 10;
      this.controls.maxPolarAngle = Math.PI / 2;

      // world
      const loadingScreen = document.getElementById('loading-screen');
      const loadingManager = new THREE.LoadingManager();
      loadingManager.onStart = function (url, itemsLoaded, itemsTotal) {
        console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
        loadingScreen.classList.add('fade-in');
      };
      loadingManager.onLoad = () => {
        // console.log( 'Loading complete!');
        loadingScreen.classList.add('fade-out');
      };
      loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
        console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
      };
      loadingManager.onError = function (url) {
        console.log('There was an error loading ' + url);
      };

      const mtlLoader = new MTLLoader(loadingManager);
      mtlLoader.setPath('obj/');
      mtlLoader.load(this.filename + '.mtl', (materials) => {
        materials.preload();

        const objLoader = new OBJLoader(loadingManager);
        objLoader.setMaterials(materials);
        objLoader.setPath('obj/');
        objLoader.load(this.filename + '.obj', (object) => {
          let box = new THREE.Box3().setFromObject(object);
          let center = new THREE.Vector3();
          box.getCenter(center);
          object.position.sub(center); // center the model
          this.scene.add(object);
        });
      });

      // lights
      const ambientLight = new THREE.AmbientLight(0xcccccc, 1.0);
      this.scene.add(ambientLight);

      // events listeners
      window.addEventListener('resize', this.resizeRenderer);
    },
    animate: function () {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
      // this is a fix to resize the renderer when the parent div size gets initialised:
      // when the parent width and height is expressed in %, it seems that it takes some time for Vue to render it
      // it also ensure the canvas is the correct size at all time - making it responsive
      if (this.canvas.width != this.canvas.clientWidth) {
        this.resizeRenderer();
      }
    },
    resizeRenderer: function () {
      // new size
      const aspectRatio = this.canvas.clientWidth / this.canvas.clientHeight;
      this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight, false);

      // update camera
      this.camera.aspect = aspectRatio;
      this.camera.updateProjectionMatrix();

      // call render to update the rendering
      this.renderer.render(this.scene, this.camera);
    }
  }
};
</script>

<style>
.three-canvas {
  width: 100%;
  height: 100%;
  display: block;
}
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #585858;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  opacity: 0;
  transition: 1s opacity;
}
.loading.fade-in {
  transition-delay: 1s;
  opacity: 1;
}
.loading.fade-out {
  transition-delay: 0s;
  opacity: 0;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
