<template>
  <div class="proj-wrap pb-3">
    <!--template rows 55px  auto <ul class="breadcrumbs-container breadcrumb "><li><router-link :to="`/accueil`" class="current-breadcrumb cursor" style="pointer-events: auto !important; cursor: pointer !important;">Accueil</router-link></li> </ul> -->
    <projectContainer v-if="projet" :project="projet" :nom-projet="projet.nom" />
  </div>
</template>

<script>
// @ is an alias to /src

import projectContainer from '@/components/ProjectContainer.vue';
import gql from 'graphql-tag';
// import router from '@/router';


export default {
  name: 'ProjectView',
  components: {
    projectContainer
  },
  props: {
    id: { type: String, default: '' }
  },
  data() {
    return {
      projet: null,
      // projectNotFound: false,
      componentKey: 0
    };
  },
  mounted() {
    this.$apollo.queries.projet.refetch();
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    }
  },
  apollo: {
    $client: 'apolloProjectClient',
    $subscribe: {
      onUpdateProject: {
        query: gql`
          subscription {
            onUpdateProject {
              projet {
                id
              }
            }
          }
        `,
        result() {
          this.$apollo.queries.projet.refetch();
        }
      }
    },

    projet: {
      // gql query
      query: gql`
        query getProject($id: Int!) {
          projet(id: $id) {
            id
            geom
            nom
            epsg
            etatTraitement
            etatPreTraitement
            etatReceptionPhotos
            thubnail
            listImages
            otherPhotos
            etatGeoref
            georeferenced
            precisionGeoref
            needGeorefUpdate
            modelApn
            focalLength
            dateDepot
            resolutionX
            resolutionY
            ErreurMoyEchelle
            ErreurSMaxEchelle
            tempsDeep
            dateFinDeep
            dateGeoref
            operateurTerrain
            operateurGeoref
            operateurDigit
            typeTraveau
            sectionSize
            commentaire
            qrCode
            grilleRepiquage
            longueur
            statusProjet
            premiumProject
            dateDigitalisation
            digitalised
            commande
            potreePath
            projectState
            nbAlignedImages
            nbTotalImages
            dateValidation
            dateArchivage
            operateurValidation
            operateurArchivage
            georefpointSet {
              id
              nom
              x
              y
              z
              xProjet
              yProjet
              zProjet
              used
              nomFichier
            }
            rtk
            archived
            scores
            scoresMobile
            indiceIonoSphere
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          id: parseInt(this.id)
        };
      },
      result({ data, _ref }) {
        const errorMessage = "Le projet demandé n'existe pas!";
        try {
          if (!data || !data.projet || (_ref && _ref.error && _ref.error.gqlError)) {
            this.$raphalUtils.goto('/accueil', () => {
              window.Toast.fire({
                icon: 'error',
                title: errorMessage
              });
            });
          } else {
            this.$route.meta.breadcrumb.label = data.projet ? data.projet.nom : '';
            this.forceRerender();
            const viewer = window.viewer;
            if (viewer) {
              viewer.scene.needGeorefUpdate = data.projet.needGeorefUpdate;
            }
          }
        } catch (_ex) {
          window.Toast.fire({
            icon: 'error',
            title: errorMessage
          });
        }
      },
      fetchPolicy: 'no-cache'
    }
  }
};
</script>
<style>
.breadcrumb {
  list-style: none;
  overflow: hidden;
  font:
    18px Helvetica,
    Arial,
    Sans-Serif;
  margin: 5px;
  padding: 0;
}
.breadcrumb li {
  float: left;
}
.breadcrumb li a {
  color: #777777;
  text-decoration: none;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  border: 1px solid #777777;
  position: relative;
  display: block;
  float: left;
  margin-left: 30px;
  background: #ffffff;
}
.breadcrumb li a:after {
  content: '▶';
  display: block;
  width: 0px;
  left: calc(100% + 12px);
  height: 0;
  position: absolute;
  top: 19%;
  font:
    20px Helvetica,
    Arial,
    Sans-Serif;
  z-index: 2;
}

.breadcrumb li:first-child a {
  margin-left: 0px;
}
.breadcrumb li:last-child a {
  border: 1px solid #37a8df;

  color: #777777;
  pointer-events: none;
  cursor: default;
}
.breadcrumb li:last-child a:after,
.breadcrumb li:last-child a:before {
  display: none;
}

.breadcrumb li a:hover {
  background: #e7e7e7;
}

.proj-wrap {
  display: grid;
  grid-template-rows: auto;
}

@media (min-width: 768px) {
  .proj-wrap {
    display: flex;
    /*min-height: 100%;*/
    grid-template-rows: unset;
  }
}
</style>
