<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="relative bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="modal-overlay" :style="[showloading ? { display: 'flex' } : { display: 'none' }]">
          <div class="w-full flex justify-center items-center">
            <h4 class="labelfistline font-bold" style="color: #fff; margin-top: 200px">
              <span v-if="operationType">Ajout du nouvel utilisateur en cours...</span>
              <span v-else>Enregistrement des modifications en cours...</span>
            </h4>
            <div class="loading-spinner" />
          </div>
        </div>

        <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <form ref="mainForm" novalidate @submit.prevent="register">
              <h1 class="mb-8 text-3xl text-center">
                {{ operationName }}
              </h1>
              <div class="mb-4">
                <input
                  v-model="firstname"
                  type="text"
                  class="block border border-grey-light w-full p-2 rounded mb-4"
                  name="Nom"
                  title="Nom"
                  placeholder="Nom"
                  required
                  @blur="validateField"
                >
                <p class="flex-1 hidden text-red-700 text-xs">
                  Veuillez spécifier le nom.
                </p>
              </div>

              <div class="mb-4">
                <input
                  v-model="lastname"
                  type="text"
                  class="block border border-grey-light w-full p-2 rounded mb-4"
                  name="prenom"
                  title="Prénom"
                  placeholder="Prénom"
                  required
                  @blur="validateField"
                >
                <p class="flex-1 hidden text-red-700 text-xs">
                  Veuillez spécifier le prénom.
                </p>
              </div>

              <div class="mb-4">
                <input
                  v-if="!operationType"
                  v-model="username"
                  type="text"
                  class="block border border-grey-light w-full p-2 rounded my-2"
                  name="userName"
                  title="Nom utilisateur"
                  placeholder="Nom utilisateur"
                  required
                  @blur="validateField"
                >
                <p class="flex-1 hidden text-red-700 text-xs">
                  Veuillez spécifier le login Teria.
                </p>
              </div>

              <div class="mb-4">
                <input
                  v-model="email"
                  type="text"
                  class="block border border-grey-light w-full p-2 rounded my-2"
                  name="email"
                  title="Email"
                  placeholder="Email"
                  required
                  @blur="validateField"
                >
                <p class="flex-1 hidden text-red-700 text-xs">
                  Veuillez spécifier l'adresse email.
                </p>
              </div>

              <t-rich-select
                v-if="(roleName === 'admin') && (userType === 'user' || userType === 'supervisor')"
                ref="selectedManager"
                v-model="manager"
                :prefetch-options="initialManagers"
                :fetch-options="fetchOptions"
                placeholder="Manager"
                search-box-placeholder="Chercher par email ou username"
                value-attribute="id"
                text-attribute="username"
                :clearable="true"
                class="pb-4"
              >
                <template slot="label" slot-scope="{ option }">
                  <div class="flex">
                    <div class="flex flex-col ml-2 text-gray-800">
                      <strong>{{ option.raw.username }}</strong>
                      <span class="text-sm leading-tight text-gray-700">{{ option.raw.email }}</span>
                    </div>
                  </div>
                </template>
              </t-rich-select>
              <t-rich-select
                v-if="
                  (userRow == null && roleName === 'admin' && userType === 'user') ||
                    (userRow == null && roleName === 'manager' && userType === 'user')
                "
                v-model="supervisor"
                :fetch-options="fetchOptionsSpervisor"
                placeholder="Superviseur"
                search-box-placeholder="Chercher par email ou username"
                value-attribute="id"
                text-attribute="username"
                :clearable="true"
                class="pb-4"
              >
                <template slot="label" slot-scope="{ option }">
                  <div class="flex">
                    <div class="flex flex-col ml-2 text-gray-800">
                      <strong>{{ option.raw.username }}</strong>
                      <span class="text-sm leading-tight text-gray-700">{{ option.raw.email }}</span>
                    </div>
                  </div>
                </template>
              </t-rich-select>
              <label v-if="isRtk || isTeria || roleName === 'admin'" class="flex items-center mb-4">
                <t-checkbox v-model="useRtk" name="useRtk" :disabled="isTeria && (userType === 'supervisor')" />
                <span class="ml-2 text-sm">RTK</span>
              </label>
              <label v-if="(isTeria || roleName === 'admin') && (userType === 'manager')" class="flex items-center mb-4">
                <t-checkbox v-model="useTeria" name="useTeria" />
                <span class="ml-2 text-sm">Teria</span>
              </label>
              <label v-if="userType === 'supervisor'" class="flex items-center mb-4">
                <t-checkbox v-model="useJetons" name="chks_password" />
                <span class="ml-2 text-sm">Autorisé à valider les projets {{ getNbrJetons }}</span>
              </label>
              <label v-if="userType === 'manager' && operationType" class="flex items-center mb-4">
                <t-checkbox v-model="usePgoc" name="chks_password" />
                <span class="ml-2 text-sm">Autorisé à utiliser le PGOC</span>
              </label>
              <label v-if="userType === 'supervisor'" class="flex items-center mb-4">
                <t-checkbox v-model="addAll" name="chks_password" @change="selectAllUsers($event)" />
                <span class="ml-2 text-sm">Tous les Utilisateurs</span>
              </label>

              <multiselect
                v-if="userType == 'supervisor'"
                id="ajax"
                v-model="usersMulti"
                class="mb-4"
                track-by="id"
                open-direction="bottom"
                :options="users"
                :multiple="true"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :clear-on-select="true"
                :close-on-select="false"
                :options-limit="300"
                :limit-text="limitText"
                :max-height="600"
                :show-no-results="true"
                :hide-selected="true"
                :show-labels="false"
                placeholder="Sélectionner utilisateurs"
                @search-change="fetchOptionsSupervisors"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag bg-raphal-termine rounded-xl my-2 mx-1 p-2">
                    <span class="ml-2 text-white">{{ option.username }}</span>
                    <span class="custom__remove cursor-pointer absolute right-5" @click="remove(option)">❌</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <div class="flex">
                      <div class="flex flex-col ml-2">
                        <strong>{{ props.option.username }}</strong>
                        <span class="text-sm leading-tight">{{ props.option.email }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="clear" slot-scope="props">
                  <div
                    v-if="usersMulti.length"
                    class="multiselect__clear"
                    @mousedown.prevent.stop="clearAll(props.search)"
                  />
                </template>
                <span slot="noResult">Pas de résultats.</span>
                <span slot="noOptions">Liste Vide.</span>
                <span>Liste Vide.</span>
              </multiselect>

              <div>
                <label v-if="operationType" class="form-label-check flex items-center mb-4">
                  <t-checkbox v-model="changepassword" name="chks_password" @focus="clearInvalid($event.currentTarget, true)" />
                  <span class="ml-2 text-sm">Changer le mot de passe</span>
                </label>
                <div class="form-field flex" :class="[changepassword ? 'block' : 'hidden']">
                  <div class="flex flex-col flex-1">
                    <input
                      v-model="password1"
                      type="text"
                      class="flex-1 block border border-grey-light w-full p-2 rounded my-2 mr-4"
                      name="password"
                      placeholder="Mot de passe"
                      :required="changepassword"
                      minLength="8"
                      @blur="validateField"
                    >
                    <p class="flex-1 hidden text-red-700 text-xs">
                      <span>- Veuillez spécifier un mot de passe ayant au minimum 8 caractères</span><br>
                      <span v-if="operationType">- Ou vider tout et décocher le champ ci-dessus pour conserver l'ancien</span>
                    </p>
                  </div>
                  <button
                    v-if="!operationType"
                    class="flex-none w-9 h-9 text-center p-1 rounded bg-gray-400 text-white hover:bg-gray-500 focus:outline-none my-1"
                    @click="generate"
                  >
                    <svg
                      id="Capa_1"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      class="w-7 h-7"
                      viewBox="0 0 511.99 511.99"
                      style="enable-background: new 0 0 511.99 511.99"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M320,207.995h-16v-32c0-35.296-28.704-64-64-64s-64,28.704-64,64v32h-16c-8.832,0-16,7.168-16,16v128
                                c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16v-128C336,215.163,328.832,207.995,320,207.995z M272,207.995h-64v-32
                                c0-17.632,14.368-32,32-32s32,14.368,32,32V207.995z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M501.952,161.147c-8.16-3.264-17.504,0.704-20.768,8.928l-9.568,23.936C444.16,91.643,350.88,15.995,240,15.995
                                c-132.352,0-240,107.648-240,240s107.648,240,240,240c64.096,0,124.384-24.96,169.728-70.272c6.24-6.24,6.24-16.384,0-22.624
                                s-16.384-6.24-22.624,0c-39.328,39.264-91.552,60.896-147.104,60.896c-114.688,0-208-93.312-208-208s93.312-208,208-208
                                c99.872,0,183.488,70.784,203.424,164.8l-32.096-32.128c-6.24-6.24-16.384-6.24-22.624,0s-6.24,16.384,0,22.624l64,64
                                c3.008,3.072,7.104,4.704,11.296,4.704c1.056,0,2.144-0.096,3.232-0.32c5.28-1.088,9.632-4.736,11.616-9.728l32-80
                                C514.112,173.723,510.144,164.411,501.952,161.147z"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>

              <div>
                <input
                  v-if="showTeriaField"
                  v-model="loginTeria"
                  type="text"
                  required="false"
                  class="block border border-grey-light w-full p-2 rounded my-2"
                  name="loginTeria"
                  placeholder="Login TERIA"
                  @blur="validateField"
                >
                <p class="flex-1 hidden text-red-700 text-xs">
                  Veuillez spécifier le login Teria.
                </p>
              </div>

              <label v-if="operationType && showTeriaField" class="form-label-check flex items-center my-2">
                <t-checkbox
                  v-model="changepasswordTeria"
                  name="chks_passwordTeria"
                  @focus="clearInvalid($event.currentTarget, true)"
                />
                <span class="ml-2 text-sm">Changer le mot de passe Teria</span>
              </label>
              <div :class="['form-field', changepasswordTeria ? 'block' : 'hidden']">
                <input
                  v-if="showTeriaField"
                  v-model="passwordTeria"
                  type="text"
                  class="block border border-grey-light w-full p-2 rounded my-2 invalid:border-red-500 focus:invalid:border-red-500"
                  name="passwordTeria"
                  placeholder="Mot de passe TERIA"
                  :required="changepasswordTeria"
                  minLength="8"
                  @blur="validateField"
                >
                <p class="hidden text-red-700 text-xs">
                  <span>- Veuillez spécifier un mot de passe Teria ayant au minimum 8 caractères</span><br>
                  <span v-if="operationType">- Ou vider tout et décocher le champ ci-dessus pour conserver l'ancien.</span>
                </p>
              </div>

              <button
                v-if="!operationType"
                type="submit"
                class="w-full text-center py-3 rounded bg-raphal-traiter text-white hover:bg-raphal-termine focus:outline-none my-1"
              >
                Ajouter
              </button>
              <button
                v-else
                type="submit"
                class="w-full text-center py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
              >
                Modifier Utilisateur
              </button>
              <button
                class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
                @click.prevent="closeDialog"
              >
                Fermer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { apolloClient } from '../vue-apollo';
import { mapGetters } from 'vuex';

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'ModalUSer',
  components: {
    Multiselect
  },
  props: {
    refreshTable: { type: Function, default: null }
  },
  data() {
    return {
      showModal: false,
      showloading: false,
      operationName: 'Modifier Utili',
      operationType: false,
      userRow: null,
      firstname: null,
      lastname: null,
      username: null,
      email: null,
      password1: null,
      loginTeria: null,
      passwordTeria: null,
      role: null,
      changepassword: false,
      useJetons: false,
      usePgoc: false,
      useRtk: false,
      useTeria: false,
      isTeriaManagerSelected: false,
      changepasswordTeria: false,
      addAll: false,
      id: null,
      manager: '512',
      supervisor: '',
      userType: '',
      firstLoad: true,
      initialManagers: [{
        id: '512',
        username: "rakoto-io"
      }],
      usersMulti: [],
      users: [],
      isLoading: false,
      redBorder: 'border-red-500'
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser',
      isRtk: 'Auth/isRtk',
      isTeria: 'Auth/isTeria'
    }),
    getNbrJetons() {
      if (this.roleName == 'manager') {
        const { totalJetons, usedJetons } = this.user;
        const remainingJetons = (totalJetons && usedJetons) ? totalJetons - usedJetons: 0;
        return `(Jetons restants ${remainingJetons})`;
      } else return '';
    },
    showTeriaField() {
      return (this.userType == 'supervisor') && (this.isTeriaManagerSelected || this.user.teria || /teria/i.test(this.user.username) || (this.userRow && this.userRow.manager && this.userRow.manager.teria));
    }
  },
  watch: {
    manager: function () {
      if (this.role == 4) {
        this.usersMulti = [];
        this.addAll = false;
      }
      this.isTeriaManagerSelected = this.isTeriaManager(this.$refs.selectedManager);
    }
  },
  updated() {
    if (this.firstLoad) {
      this.initialManagers = this.userRow && this.userRow.manager ? [this.userRow.manager] : [];
      this.manager = this.userRow && this.userRow.manager ? this.userRow.manager.id : '';
      this.firstLoad = false;
    }
  },
  methods: {
    limitText(count) {
      return `et ${count} autre utilisateurs`;
    },
    isTeriaManager (user) {
      if (!user || !user.selectedOption || !user.selectedOption.raw) {
        return false;
      }
      return user.selectedOption.raw.teria;
    },
    isNextSiblingParagraph(el) {
      return el && el.tagName && (el.tagName.toLowerCase() === 'p');
    },
    clearInvalid(el, checkbox=false) {
      el.classList.remove(this.redBorder);
      let nextSibling = el.nextSibling;
      if (checkbox) {
        const parentEl = el.parentElement.nextSibling;
        const inputEl = parentEl.querySelector('input[type="text"]');
        if (inputEl) {
          inputEl.classList.remove(this.redBorder);
        }
        nextSibling = parentEl.querySelector('p');
      }
      if (this.isNextSiblingParagraph(nextSibling)) {
        nextSibling.classList.add('hidden');
      }
    },
    validateField(e) {
      e.preventDefault();
      const el = e.currentTarget;
      if (el.checkValidity()) {
        this.clearInvalid(el);
      }
    },
    /* validateField_old(e) {
      e.preventDefault();
      const el = e.currentTarget;
      let cancel = ((el.name === 'password') && this.changepassword) || ((el.name === 'passwordTeria') && this.changepasswordTeria);
      if ((el.name === 'password') || (el.name === 'passwordTeria')) {
        cancel &&= this.checkboxHasFocus;
      }
      if (cancel) {
        return false;
      }
      const nextSibling = el.nextSibling;
      if (el.value.length < 4) {
        el.classList.add(this.redBorder);
        if (this.isNextSiblingParagraph(nextSibling)) {
          nextSibling.classList.remove('hidden');
        }
      } else {
        this.clearInvalid(el);
      }
    }, */
    async selectAllUsers(e) {
      if (e == true) {
        await this.fetchOptionsSupervisors('');
        this.usersMulti = this.users;
      } else {
        this.usersMulti = [];
      }
    },
    toggleModal (userRow, operationName, operationType, userType, roleUser=3) {
      this.showModal = !this.showModal;
      this.userRow = userRow;
      this.operationType = operationType;
      this.operationName = operationName;
      this.userType = userType;
      // this.isTeriaManagerSelected = false;
      if (this.userRow != null) {
        const { id, firstName, lastName, username, email, role, rtk, teria, useJetons, usePgoc, hasAllUsers, supervisors, paramsNtrip } = this.userRow;
        this.firstname = firstName;
        this.lastname = lastName;
        this.username = username;
        this.email = email;
        this.role = role.name;
        this.useRtk = rtk;
        this.useTeria = this.isTeria || teria;
        this.useJetons = useJetons;
        this.usePgoc = usePgoc;
        this.addAll = hasAllUsers;
        this.id = id;
        this.loginTeria = paramsNtrip ? paramsNtrip.user : null;
        if (this.userType === 'supervisor') {
          this.usersMulti = supervisors;
        }
        this.firstLoad = true;
      } else {
        this.firstname = null;
        this.lastname = null;
        this.username = null;
        this.email = null;
        this.id = null;
        this.addAll = false;
        this.useRtk = this.isTeria && (this.userType === 'supervisor');
        this.useTeria = this.isTeria && (this.userType === 'supervisor');
        this.useJetons = false;
        this.usePgoc = false;
        this.role = roleUser;
        this.usersMulti = [];
        this.loginTeria = null;
        this.passwordTeria = null;
        this.firstLoad = false;
      }
      if (this.operationType == false) {
        this.changepassword = true;
        this.changepasswordTeria = true;
      }
    },
    async fetchOptions(q) {
      try {
        let { data: data } = await apolloClient.query({
          query: gql`
            query ($role: String!, $searchQyery: String!) {
              searchUser(role: $role, searchQyery: $searchQyery) {
                id
                username
                email
                rtk
                teria
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            role: 'manager',
            searchQyery: q
          }
        });
        this.users = data.searchUser.filter((e) => e.id !== this.id);
        return {
          results: data.searchUser
        };
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },
    async fetchOptionsSpervisor(q) {
      try {
        let { data: data } = await apolloClient.query({
          query: gql`
            query ($role: String!, $searchQyery: String!) {
              searchUser(role: $role, searchQyery: $searchQyery) {
                id
                username
                email
                rtk
                teria
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            role: 'supervisor',
            searchQyery: q
          }
        });
        this.users = data.searchUser.filter((e) => e.id !== this.id);
        return {
          results: data.searchUser
        };
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },
    async fetchOptionsSupervisors(q) {
      try {
        let { data: data } = await apolloClient.query({
          query: gql`
            query ($role: String!, $searchQyery: String!) {
              searchUser(role: $role, searchQyery: $searchQyery) {
                id
                username
                email
                rtk
                teria
                manager {
                  id
                  teria
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            role: 'user',
            searchQyery: q
          }
        });

        if (this.roleName == 'admin' && this.role == 4) {
          if (this.manager) {
            this.users = data.searchUser.filter(
              (e) => e.id !== this.id && e.manager != null && e.manager.id === this.manager
            );
          } else {
            alert('Veuillez sélectionner le Manager');
            this.addAll = false;
            this.users = [];
          }
        } else {
          this.users = data.searchUser.filter((e) => e.id !== this.id);
        }
        return {
          results: this.users
        };
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        // eslint-disable-next-line
        Toast.fire({
          icon: 'error',
          title: err.nonFieldErrors[0].message
        });
      }
    },
    clearForm () {
      this.operationName = 'Modifier Utili';
      this.operationType = false;
      this.userRow = null;
      this.firstname = null;
      this.lastname = null;
      this.username = null;
      this.email = null;
      this.password1 = null;
      this.role = null;
      this.useRtk = false;
      this.userTeria = false;
      this.useJetons = false;
      this.usePgoc = false;
      this.changepassword = false;
      this.changepasswordTeria = false;
      this.id = null;
      this.manager = '';
      this.supervisor = '';
      this.addAll = false;
      this.loginTeria = null;
      this.passwordTeria = null;
      this.isTeriaManagerSelected = false;
      const form = this.$refs.mainForm;
      form.reset();
      form.classList.remove("validated");
    },
    closeDialog () {
      this.showModal = false;
      this.clearForm();
    },
    generate (e) {
      e.preventDefault();
      let charactersArray = 'a-z,0-9';
      let CharacterSet = '';
      let password = '';

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789';
      }
      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|';
      }

      for (let i = 0; i < 10; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      this.password1 = password;
    },
    register (e) {
      e.preventDefault();
      const form = e.currentTarget;
      form.classList.add("validated");
      if (!form.checkValidity()) {
        form.querySelectorAll(":invalid").forEach((el, k) => {
          let parentEl = el.parentElement;
          while (parentEl) {
            if (parentEl.classList.contains('form-field') || (parentEl.tagName === 'body')) {
              break;
            }
            parentEl = parentEl.parentElement;
          }
          if (parentEl && parentEl.classList.contains('form-field')) {
            let parentPreviousSibling = parentEl.previousSibling;
            if (parentPreviousSibling && parentPreviousSibling.tagName && parentPreviousSibling.classList.contains('form-label-check')) {
              const checkboxEl = parentPreviousSibling.querySelector('input[type="checkbox"]');
              if (checkboxEl && !checkboxEl.checked) {
                checkboxEl.click();
              }
            }
          }
          el.classList.add(this.redBorder);
          const nextSibling = el.nextSibling;
          if (nextSibling.tagName.toLowerCase() === 'p') {
            nextSibling.classList.remove('hidden');
          }
          if (k === 0) {
            el.focus();
          }
        });
        return;
      }

      this.showloading = true;
      let mutation = null;
      if (this.userRow == null) {
        mutation = gql`
          mutation register_user(
            $username: String!
            $email: String!
            $password1: String!
            $password2: String!
            $firstName: String!
            $lastName: String!
            $role: String!
            $manager: String!
            $rtk: String!
            $teria: Boolean!
            $supervisors: String
            $supervisor: String
            $loginTeria: String
            $passwordTeria: String
            $useJetons: Boolean!
          ) {
            register(
              email: $email
              username: $username
              password1: $password1
              password2: $password2
              firstName: $firstName
              lastName: $lastName
              manager: $manager
              role: $role
              rtk: $rtk
              teria: $teria
              loginTeria: $loginTeria
              passwordTeria: $passwordTeria
              supervisors: $supervisors
              supervisor: $supervisor
              useJetons: $useJetons
            ) {
              success
              errors
            }
          }
        `;
      } else {
        mutation = gql`
          mutation update_user(
            $id: Int!
            $password1: String
            $password2: String
            $firstName: String!
            $lastName: String!
            $email: String!
            $changePassword: Boolean!
            $manager: String!
            $supervisors: String
            $rtk: Boolean!
            $teria: Boolean!
            $loginTeria: String
            $passwordTeria: String
            $changePasswordTeria: Boolean!
            $useJetons: Boolean!
            $usePgoc: Boolean!
          ) {
            updateUser(
              id: $id
              password1: $password1
              password2: $password2
              firstname: $firstName
              lastname: $lastName
              email: $email
              changePassword: $changePassword
              rtk: $rtk
              teria: $teria
              loginTeria: $loginTeria
              passwordTeria: $passwordTeria
              changePasswordTeria: $changePasswordTeria
              useJetons: $useJetons
              usePgoc: $usePgoc
              manager: $manager
              supervisors: $supervisors
            ) {
              user {
                username
              }
            }
          }
        `;
      }

      const msgMap = {
        "username": "Nom utilisateur",
        "password1": "Mot de passe",
        "password2": "Mot de passe",
        "firstName": "Nom et/ou Prénom",
        "lastName": "Nom et/ou Prénom"
      }

      this.$apollo
        .mutate({
          mutation: mutation,
          variables: {
            id: this.id,
            email: this.email,
            username: this.username,
            firstName: this.firstname,
            lastName: this.lastname,
            role: this.role,
            rtk: this.useRtk,
            teria: this.useTeria,
            useJetons: this.useJetons,
            usePgoc: this.usePgoc,
            password1: this.password1,
            password2: this.password1,
            changePassword: this.changepassword,
            loginTeria: this.loginTeria,
            passwordTeria: this.passwordTeria,
            changePasswordTeria: this.changepasswordTeria,
            manager: this.manager,
            supervisor: this.supervisor,
            supervisors: this.usersMulti.map((x) => parseInt(x.id))
          }
        })
        .then((data) => {
          // Result
          if (data.data.register) {
            if (data.data.register.success == true) {
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.showModal = false;
              this.refreshTable();
              this.clearForm();
            } else {
              /* let listErrors = '';
              let dictErrors = [
                'email',
                'username',
                'password1',
                'password2',
                'firstName',
                'lastName',
                'nonFieldErrors'
              ];
              for (let error of dictErrors) {
                if (data.data.register.errors[error]) {
                  for (let err of data.data.register.errors[error]) {
                    listErrors += err.message + '\n';
                  }
                }
              } */
              let msg = '';
              if (data.data.register.errors) {
                const errors = data.data.register.errors;
                for (let k in errors) {
                  msg = errors[k].map(e => e.message.replaceAll(/(this\s.+\sis\s)/gi, `${msgMap[k]} is `)).join('\n');
                }
              }
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: msg
              });
            }
          } else if (data.data.updateUser) {
            if (data.data.updateUser != null) {
              this.userRow.firstName = this.firstname;
              this.userRow.lastName = this.lastname;
              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: 'Opération effectuée avec succès !'
              });
              this.showModal = false;
              this.refreshTable();
              this.clearForm();
            } else {
              let msg = '';
              if (data.data.register.errors) {
                const errors = data.data.register.errors;
                for (let k in errors) {
                  msg = errors[k].map(e => e.message.replaceAll(/(this\s.+\sis\s)/gi, `${msgMap[k]} is `)).join('\n');
                }
              }

              /* 
              let listErrors = '';
              let dictErrors = [
                'email',
                'username',
                'password1',
                'password2',
                'firstName',
                'lastName',
                'nonFieldErrors'
              ];
              for (let error of dictErrors) {
                if (data.data.register.errors[error]) {
                  for (let err of data.data.register.errors[error]) {
                    listErrors += err.message + '\n';
                  }
                }
              } */

              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: msg
              });
            }
          }
          this.showloading = false;
        })
        .catch((error) => {
          // Error
          // eslint-disable-next-line no-console
          console.error(error);
          let msg = '';
          if (error.networkError && error.networkError.result && error.networkError.result.errors) {
            const errors = error.networkError.result.errors;
            msg = errors.map(e => e.message.replaceAll(/(variable\s|\$|\sof.+type.+".+")/gi, '')).join('\n');
            const rgx = new RegExp(`(${Object.keys(msgMap).join('|')})`, 'g');
            msg = msg.replaceAll(rgx, (m) => {
              return msgMap[m];
            });
          } else {
            msg = error.message;
          }
          this.showloading = false;
          // eslint-disable-next-line
          Toast.fire({
            icon: 'error',
            title: msg
          });
          // We restore the initial user input
        });
    }
  }
};
</script>
<style>
.multiselect__select::before {
  @apply text-gray-1;
}
.multiselect__tags {
  @apply border-gray-1;
  height: 100px;
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.multiselect__tag,
.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight::after {
  @apply bg-raphal-termine text-white;
}
.multiselect__tag-icon::after {
  @apply text-white;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  @apply bg-black-1 text-white;
}
.multiselect__content-wrapper {
  @apply border-gray-1;
}
.multiselect__option--highlight {
  @apply bg-blue-raphal text-white;
}
.multiselect__option--highlight::after {
  @apply bg-blue-raphal text-white;
}
.multiselect__input,
.multiselect__input:focus {
  @apply shadow-none ring-0 border-0;
}
.modal-overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(16, 16, 16, 0.7);
  z-index: 100;
  cursor: pointer;
}
.loading-spinner {
  height: 60px;
  width: 60px;
  margin: auto;
  display: flex;
  position: absolute;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
